import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import goodchoice from "../../assets/images/GoodChoice.png";
import badchoice from "../../assets/images/WarningChoice.png";
import AppButton from "../BaseComponents/Button";
import type { TutorialStepProps } from "./TutorialStepProps";

export type SwapBadgeProps = TutorialStepProps;

export const SwapBadge = styled(
  ({ className, onBack, onDone }: SwapBadgeProps) => (
    <div className={`flex flex-col justify-around max-h-screen ${className}`}>
      <div
        className="relative w-full max-w-655 h-805 m-auto img-field"
        css={css`
          height: 680px;
        `}
      >
        <img
          src={goodchoice}
          alt="Good Choice"
          className="absolute top-[150px] right-[0px] z-10 good-choice"
          css={css`
            top: 50px;
          `}
        />
        <img
          src={badchoice}
          alt="Bad Choice"
          className="absolute top-[289px] right-[250px] bad-choice"
          css={css`
            top: 189px;
          `}
        />
      </div>

      <div
        className="flex justify-center items-center btn-field w-full"
        css={css`
          margin-bottom: 52px;
        `}
      >
        <AppButton
          onClick={onBack}
          className="w-full max-w-271 !text-snap-blue bg-white border-snap-blue font-mulish"
        >
          BACK
        </AppButton>
        <AppButton
          onClick={onDone}
          className="w-full max-w-271  btn-done font-mulish"
        >
          CONTINUE
        </AppButton>
      </div>
    </div>
  ),
)`
  .btn-field {
    .btn-done {
      margin-left: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin: 30px 40px 30px 40px;
    .img-field {
      position: inherit;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-item: center;
      height: auto;
      img {
        position: inherit;
        width: 100%;
        max-width: 400px;
        max-height: 400px;
        margin-bottom: 20px;
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .btn-field {
      margin-bottom: 0;

      align-items: center;
      flex-direction: column-reverse;

      .btn-done {
        margin-left: 0;
        margin-top: 3rem;
        margin-bottom: 1rem;
      }
    }
  }
`;
