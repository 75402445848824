import type { RouteComponentProps } from "@reach/router";
import React from "react";

import TutorialSection from "../sections/TutorialSection";
import { SessionLoader } from "../session";
import { assertDefined } from "../utils";

function TutorialPage({ location }: RouteComponentProps) {
  assertDefined(location);

  return (
    <SessionLoader audience="authenticated" location={location}>
      <TutorialSection />
    </SessionLoader>
  );
}

export default TutorialPage;
