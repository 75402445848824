import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import type { Except } from "type-fest";

import AppButton from "../BaseComponents/Button";
import type { TutorialStepProps } from "./TutorialStepProps";

export type WelcomeTutorialProps = Except<TutorialStepProps, "onBack">;

export const WelcomeTutorial = styled(
  ({ className, onDone }: WelcomeTutorialProps) => (
    <div className={`${className} flex flex-col justify-around max-h-screen`}>
      <div />
      <div className="content text-snap-black">
        <div className="font-semibold text-[18px] leading-[54px] title">
          In the next 60 seconds, you’ll learn:
        </div>
        <div className="flex items-center item">
          <div className="w-27 h-27 flex justify-center items-center rounded-[50%] bg-snap-blue text-white mr-[10px]">
            1
          </div>
          <div className="text-[18px] leading-[54px] text">How to use Swap</div>
        </div>
        <div className="flex items-center item">
          <div className="w-27 h-27 flex justify-center items-center rounded-[50%] bg-snap-blue text-white mr-[10px]">
            2
          </div>
          <div className="text-[18px] leading-[54px] text">
            How Swap knows which groceries are best
          </div>
        </div>
        <div className="flex items-center item">
          <div className="w-27 h-27 flex justify-center items-center rounded-[50%] bg-snap-blue text-white mr-[10px]">
            3
          </div>
          <div className="text-[18px] leading-[54px] text">
            How your cash back works
          </div>
        </div>
        <div className="flex items-center item">
          <div className="w-27 h-27 flex justify-center items-center rounded-[50%] bg-snap-blue text-white mr-[10px]">
            4
          </div>
          <div className="text-[18px] leading-[54px] text">
            Tips for grocery shopping on Amazon Fresh
          </div>
        </div>
      </div>
      <div className="flex justify-end btn-field">
        <AppButton
          className="w-full max-w-271 font-mulish"
          css={css`
            margin-right: 62px;
          `}
          onClick={onDone}
        >
          CONTINUE
        </AppButton>
      </div>
    </div>
  ),
)`
  .content {
    margin-left: 75px;
  }
  @media only screen and (max-width: 768px) {
    margin: 30px 40px 30px 40px;
    .content {
      margin: 0 auto;
      max-width: 400px;
    }
    .btn-field {
      justify-content: center;
      margin-top: 30px;
      button {
        margin: 0;
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .content {
      .item {
        .text {
          font-size: 13px;
        }
      }
    }
  }
`;
