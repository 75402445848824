import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import image from "../../assets/images/HowToUse.png";
import AppButton from "../BaseComponents/Button";
import type { TutorialStepProps } from "./TutorialStepProps";

export type HowToUseSwapProps = TutorialStepProps;

export const HowToUseSwap = styled(
  ({ className, onBack, onDone }: HowToUseSwapProps) => (
    <div className={`flex flex-col justify-around max-h-screen ${className}`}>
      <img
        src={image}
        alt="HowToUse"
        className="justify-self-center mx-auto"
        css={css`
          max-width: 636px;
        `}
      />
      <div className="btn-field flex justify-center">
        <AppButton
          onClick={onBack}
          className="w-full max-w-271 !text-snap-blue bg-white border-snap-blue font-mulish"
        >
          BACK
        </AppButton>
        <AppButton
          onClick={onDone}
          className="w-full max-w-271 btn-done font-mulish"
        >
          CONTINUE
        </AppButton>
      </div>
    </div>
  ),
)`
  .btn-field {
    .btn-done {
      margin-left: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin: 30px 40px 30px 40px;
    img {
      margin: 0 auto;
      width: 100%;
      max-width: 500px;
      max-height: 500px;
    }
  }
  @media only screen and (max-width: 425px) {
    .btn-field {
      align-items: center;
      flex-direction: column-reverse;

      .btn-done {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }
`;
