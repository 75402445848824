export enum SignUpStep {
  ACCOUNT_CREATION_STEP = "ACCOUNT_CREATION_STEP",
  CONTACT_INFORMATION_STEP = "CONTACT_INFORMATION_STEP",
  HEALTH_AND_BENEFITS_STEP = "HEALTH_AND_BENEFITS_STEP",
  CONFIRMATION_STEP = "CONFIRMATION_STEP",
  EMAIL_VERIFICATION_STEP = "EMAIL_VERIFICATION_STEP",
  ACCOUNT_VERIFIED_STEP = "ACCOUNT_VERIFIED_STEP",
}
export enum TutorialStep {
  WELCOME = "WELCOME",
  HOW_TO_USE = "HOW_TO_USE",
  SWAP_BADGE = "SWAP_BADGE",
  DIETITIAN_APPROVED = "DIETITIAN_APPROVED",
  CASH_BACK = "CASH_BACK",
  REMIND = "REMIND",
}

export enum UpdateUserKey {
  FIRST_NAME = "FirstName",
  LAST_NAME = "LastName",
  USER_NAME = "Username",
  EMAIL = "Email",
  PHONE = "Phone",
  ZIP_CODE = "ZipCode",
  STATE = "State",
  CITY = "City",
  ADDRESS = "Address",
  ADDRESS2 = "Address2",
  DOB = "Dob",
  HAS_EBT = "HasEbt",
  EBT_MONTLY_BENEFIT = "EbtMonthlyBenefit",
  IS_DIABETIC = "IsDiabetic",
  CURRENT_A1C = "CurrentA1c",
  LAST_A1C_MEASUREMENT = "LastA1cMeasurement",
  HAS_VIEWED_TUTORIAL = "HasViewedTutorial",
  CASHBACK = "CurrentCashback",
}
