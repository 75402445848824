import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import AppButton from "../BaseComponents/Button";
import type { TutorialStepProps } from "./TutorialStepProps";

export type RemindProps = TutorialStepProps;

export const Remind = styled(({ className, onBack, onDone }: RemindProps) => (
  <div className={`flex flex-col justify-around max-h-screen ${className}`}>
    <div />
    <div
      className="w-full max-w-535 text-[18px] leading-[27px] content justify-self-center mx-auto"
      css={css`
        color: #222;
      `}
    >
      <p>
        Remember, Swap only works on{" "}
        <span className="font-bold">Amazon Fresh</span>. To get access to Swap’s
        recommendations and cash back bonuses, you must do your grocery shopping
        on <span className="font-bold">Amazon Fresh</span>.
      </p>
      <p>
        Never ordered groceries online before?{" "}
        <a
          style={{ cursor: "pointer" }}
          className="text-snap-blue border-b border-solid"
          href="http://www.getswapapp.com/faqs-5"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </a>{" "}
        for helpful tips and guidance.
      </p>
      <p>
        {" "}
        You can also learn about{" "}
        <a
          className="text-snap-blue border-b border-solid"
          href="http://www.getswapapp.com/faqs-6"
          target="_blank"
          rel="noreferrer"
        >
          shopping with EBT
        </a>{" "}
        and{" "}
        <a
          className="text-snap-blue border-b border-solid"
          href="http://www.getswapapp.com/faqs-7"
          target="_blank"
          rel="noreferrer"
        >
          our 100% freshness guarantee
        </a>
      </p>
    </div>

    <div className="flex justify-center items-center btn-field">
      <AppButton
        onClick={onBack}
        className="w-full max-w-271 !text-snap-blue bg-white border-snap-blue font-mulish"
      >
        BACK
      </AppButton>
      <AppButton
        onClick={onDone}
        className="w-full max-w-271 btn-done font-mulish"
      >
        FINISH
      </AppButton>
    </div>
  </div>
))`
  .content {
    p {
      margin-bottom: 20px;
    }
  }
  .btn-field {
    .btn-done {
      margin-left: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin: 30px 40px 30px 40px;
    .content {
      margin: 0 auto;
    }
    .btn-field {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 425px) {
    .content {
      font-size: 13px;
      line-height: 24px;
    }
    .btn-field {
      align-items: center;
      flex-direction: column-reverse;

      .btn-done {
        margin-left: 0;
        margin-top: 3rem;
        margin-bottom: 1rem;
      }
    }
  }
`;
