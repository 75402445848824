import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import FaceImage from "../../assets/images/FaceImage.png";
import Message from "../../assets/images/Message.png";
import AppButton from "../BaseComponents/Button";
import type { TutorialStepProps } from "./TutorialStepProps";

export type DietitianApprovedProps = TutorialStepProps;

export const DietitianApproved = styled(
  ({ className, onBack, onDone }: DietitianApprovedProps) => (
    <div className={`flex flex-col justify-around max-h-screen ${className}`}>
      <div />
      <div>
        <div className="w-full max-w-414 h-414 overflow-hidden face-image justify-self-center mx-auto">
          <img src={FaceImage} alt="Face" />
        </div>
        <div className="flex w-full max-w-395 m-auto message justify-self-center">
          <div className="w-130">
            <img src={Message} alt="Message" />
          </div>

          <p
            className="text-[18px] leading-[27px]"
            css={css`
              color: #222;
            `}
          >
            You’ll only ever see recommendations that Elizabeth has approved,
            and our algorithm finds you the best cash back option every time to
            put more money back in your pocket!
          </p>
        </div>
      </div>

      <div className="btn-field flex justify-center">
        <AppButton
          onClick={onBack}
          className="w-full max-w-271 !text-snap-blue bg-white border-snap-blue font-mulish"
        >
          BACK
        </AppButton>
        <AppButton
          onClick={onDone}
          className="w-full max-w-271 btn-done font-mulish"
        >
          CONTINUE
        </AppButton>
      </div>
    </div>
  ),
)`
  .face-image {
    border: 7px solid #2f80ff;
    margin-top: 25px;
    border-radius: 50%;
  }
  .message {
    p {
      margin-top: 15px;
      margin-left: 20px;
    }
  }
  .btn-field {
    .btn-done {
      margin-left: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin: 30px 40px 30px 40px;
    .face-image {
      margin: 0 auto;
      height: auto;
    }
  }
  @media only screen and (max-width: 425px) {
    .message {
      p {
        font-size: 13px;
      }
    }
    .btn-field {
      align-items: center;
      flex-direction: column-reverse;

      .btn-done {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }
`;
