import { navigate } from "gatsby";
import React, { useState } from "react";

import { analyticsEnqueueEvent } from "../analytics";
import { useUserUpdateMutation } from "../api";
import HowToUseImage from "../assets/icons/how_to_use.svg";
import CashbackImage from "../assets/images/CashBack.png";
import DietitianApprovedImage from "../assets/images/DietitianApproved.png";
import RemindImage from "../assets/images/Remind.png";
import SwapBadgeImage from "../assets/images/Swapbadge.png";
import WelcomeImage from "../assets/images/Welcome.png";
import AuthenticationLayout from "../components/Layout/AuthenticationLayout";
import {
  CashBack,
  DietitianApproved,
  HowToUseSwap,
  Remind,
  SwapBadge,
  WelcomeTutorial,
} from "../components/Tutorial";
import { useAppDispatch } from "../store";
import { TutorialStep } from "../utils/enum";

const TutorialSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const [triggerUserUpdate] = useUserUpdateMutation();
  const [tutorialStep, setTutorialStep] = useState<string>(
    TutorialStep.WELCOME,
  );
  const renderWelcomeImage = () => {
    switch (tutorialStep) {
      case TutorialStep.WELCOME:
        return WelcomeImage;
      case TutorialStep.HOW_TO_USE: {
        return HowToUseImage;
      }
      case TutorialStep.SWAP_BADGE:
        return SwapBadgeImage;
      case TutorialStep.DIETITIAN_APPROVED:
        return DietitianApprovedImage;
      case TutorialStep.CASH_BACK:
        return CashbackImage;
      case TutorialStep.REMIND:
        return RemindImage;
      default:
        return null;
    }
  };
  const renderWelcomeTitle = () => {
    switch (tutorialStep) {
      case TutorialStep.WELCOME:
        return "Welcome to Swap!";
      case TutorialStep.HOW_TO_USE: {
        return "How to Use Swap";
      }
      case TutorialStep.SWAP_BADGE:
        return "The Swap Badge";
      case TutorialStep.DIETITIAN_APPROVED:
        return "Dietitian Approved";
      case TutorialStep.CASH_BACK:
        return "Cash back";
      case TutorialStep.REMIND:
        return "You’re all set!";
      default:
        return null;
    }
  };
  const renderWelcomeContent = () => {
    switch (tutorialStep) {
      case TutorialStep.WELCOME:
        return "This 1 minute tutorial will show you how to buy fresh, diabetes-friendly groceries online and get cash back while you’re doing it!";
      case TutorialStep.HOW_TO_USE: {
        return "Look out for Swap on the right side of your screen. Swap will always be there to guide you towards the grocery items that can help you manage your diabetes and get you the most cashback";
      }
      case TutorialStep.SWAP_BADGE:
        return "If Swap thinks you’re looking at a great item, the badge will turn green. If Swap thinks there might be a better choice for you, it’ll alert you to and show healthier options that earn you more cash back.";
      case TutorialStep.DIETITIAN_APPROVED:
        return 'Swap recommends groceries based on the expert clinical guidance of <span className="font-extrabold">Elizabeth DeRobertis, MS, RD, CDN, CDE</span>, a world-renowned dietitian who specializes in treating Type 2 Diabetes.';
      case TutorialStep.CASH_BACK:
        return "You earn cash back every time you buy a Swap-approved item, and you can see just how much cash you’re earning with every item";
      case TutorialStep.REMIND:
        return "Start buying healthy groceries that help you manage your diabetes and earn you cash back!";
      default:
        return null;
    }
  };
  const renderRightSide = () => {
    switch (tutorialStep) {
      case TutorialStep.WELCOME:
        return (
          <WelcomeTutorial
            onDone={() => {
              setTutorialStep(TutorialStep.HOW_TO_USE);
            }}
          />
        );
      case TutorialStep.HOW_TO_USE:
        return (
          <HowToUseSwap
            onDone={() => {
              setTutorialStep(TutorialStep.SWAP_BADGE);
            }}
            onBack={() => {
              setTutorialStep(TutorialStep.WELCOME);
            }}
          />
        );
      case TutorialStep.SWAP_BADGE:
        return (
          <SwapBadge
            onDone={() => {
              setTutorialStep(TutorialStep.DIETITIAN_APPROVED);
            }}
            onBack={() => {
              setTutorialStep(TutorialStep.HOW_TO_USE);
            }}
          />
        );
      case TutorialStep.DIETITIAN_APPROVED:
        return (
          <DietitianApproved
            onDone={() => {
              setTutorialStep(TutorialStep.CASH_BACK);
            }}
            onBack={() => {
              setTutorialStep(TutorialStep.SWAP_BADGE);
            }}
          />
        );
      case TutorialStep.CASH_BACK:
        return (
          <CashBack
            onDone={() => {
              setTutorialStep(TutorialStep.REMIND);
            }}
            onBack={() => {
              setTutorialStep(TutorialStep.DIETITIAN_APPROVED);
            }}
          />
        );
      case TutorialStep.REMIND:
        return (
          <Remind
            onDone={async () => {
              await triggerUserUpdate({
                body: [
                  {
                    field: "HasViewedTutorial",
                    value: true,
                  },
                ],
              }).unwrap();

              dispatch(
                analyticsEnqueueEvent({
                  eventName: "onboardingCompleted",
                }),
              );

              // TODO: Fix this hack while the profile is updated...
              setTimeout(() => {
                navigate("/");
              }, 500);
              window.open(
                "https://www.amazon.com/alm/storefront?almBrandId=QW1hem9uIEZyZXNo",
                "_blank",
              );
            }}
            onBack={() => {
              setTutorialStep(TutorialStep.CASH_BACK);
            }}
          />
        );
      default:
        return null;
    }
  };
  return (
    <AuthenticationLayout
      isTutorial
      srcImage={renderWelcomeImage()}
      title={renderWelcomeTitle()}
      content={renderWelcomeContent()}
    >
      {renderRightSide()}
    </AuthenticationLayout>
  );
};

export default TutorialSection;
